<template>
  <div class="">
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">CAPACITY</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <FullCalendar :options="calendarOptions" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'

export default {
  components: {
    FullCalendar
  },
  data () {
    return {
      dialogTitle: '',
      dialogVisible: false,
      calendarOptions: {
        plugins: [ dayGridPlugin ],
        initialView: 'dayGridMonth',
        // displayEventEnd: true,
        displayEventTime: false,
        eventDisplay: 'block',
        eventBackgroundColor: 'transparent',
        eventBorderColor: 'transparent',
        eventContent: this.eventContent,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false
        },
        events: [],
        height: 580,
        headerToolbar: {
          right: 'prev next today'
        }
      }
    }
  },

  computed: {
    ...mapState({
      calendarEvents: state => state.calendar.calendar,
      capacity: state => state.ticket.capacity,
      innerHeight: state => state.nav.innerHeight
    })
  },
  methods: {
    fitDate (hour) {
      let h = Number(hour)
      return h > 6 ? h - 6 : h + 18
    },
    setCalendarEvents () {
      this.calendarOptions.events = this.calendarEvents
        // .filter(c => new Date(c.day).getTime() + 1000 * 60 * 60 * 24 > new Date().getTime())
        .map(c => c.sessions.map((s, index) => Object.assign(s, { day: c.day, idx: index })))
        .flat()
        .map(s => {
          let capacity = this.capacity.find(e => e.day === s.day && e.session === s.idx)
          let time = s.time.split(' - ')
          let day = s.day.split('-')
          let start = new Date(day[0], Number(day[1]) - 1, day[2], this.fitDate(time[0].split(':')[0]), time[0].split(':')[1])
          let end = new Date(day[0], Number(day[1]) - 1, day[2], this.fitDate(time[1].split(':')[0]), time[1].split(':')[1])
          return {
            title: capacity ? s.time + ' ' + (Number(s.capacity) - capacity.persons).toString() : s.time + ' <b>' + s.capacity + '</b>',
            start: start,
            end: end,
            timefull: s.time,
            capacity: capacity ? Number(s.capacity) - capacity.persons : s.capacity
          }
        })
    },
    eventContent (fcInfo) {
      let divWrap = document.createElement('span')
      divWrap.className = 'flex justify-space-around'
      let spanTimeText = document.createElement('span')
      spanTimeText.className = 'box'
      spanTimeText.innerHTML = fcInfo.event.extendedProps.timefull
      let spanCapacityText = document.createElement('span')

      if (fcInfo.event.extendedProps.capacity <= 10) {
        spanCapacityText.className = 'box danger-text'
      } else if (fcInfo.event.extendedProps.capacity <= 25) {
        spanCapacityText.className = 'box warning-text'
      } else {
        spanCapacityText.className = 'box success-text'
      }

      spanCapacityText.innerHTML = fcInfo.event.extendedProps.capacity
      divWrap.appendChild(spanTimeText)
      divWrap.appendChild(spanCapacityText)

      let arrayOfDomNodes = [ divWrap ]
      return { domNodes: arrayOfDomNodes }
    }
  },
  created () {
    this.setCalendarEvents()
    this.calendarOptions.height = this.innerHeight - 140
  },
  watch: {
    innerHeight: function (val, oldVal) {
      this.calendarOptions.height = val - 140
    }
  }
}
</script>

<style>
.fc .fc-bg-event {
    opacity: 1;
}
.fc .fc-view-harness a {
    color: white;
    padding-right: 16px;
}
.fc .fc-bg-event .fc-event-title {
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
}

/* .fc-event-today {
  background-image:url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNHB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDQgNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNCA0IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0FCQUJBQiIgc3Ryb2tlLXdpZHRoPSIwLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgeDE9IjAiIHkxPSIwIiB4Mj0iNCIgeTI9IjQiLz4NCjwvc3ZnPg0K") !important;
  background-repeat:repeat !important;
  opacity: 0.8 !important;
  background-position:0 0 !important;
  background-size:4px 4px !important;
} */
</style>
